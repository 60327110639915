import React from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios';
import Select from 'react-select';

class Search extends React.Component {

    state = {
        region: null,
        department: "buy",
        priceFrom: null,
        priceTo: null,
        type: null,
        bedrooms: null,
        bathrooms: null,
        opportunities: null,
        locations: [],
        types: [],
        regions: [],
        buyPrices: [
            "50000",
            "100000",
            "150000",
            "200000",
            "250000",
            "300000",
            "350000",
            "400000",
            "450000",
            "500000",
            "550000",
            "600000",
            "650000",
            "700000",
            "750000",
            "800000",
            "850000",
            "900000",
            "950000",
            "1000000+",
        ],
        letPrices: [
            "250",
            "500",
            "750",
            "1000",
            "1500",
            "2000",
            "3000",
            "4000",
            "5000"
        ]
    };

    componentDidMount(){

        // Get Property Types
        axios.get("https://api-v2.plumproperties.im/properties")
        .then((res) => {
            this.setState({
                types: [... new Set(res.data.map(property => property.type))],
                regions: [... new Set(res.data.map(property => property.address.locality))].sort()
            })
        });
    }

    componentDidUpdate(){
        if(this.props.location.pathname.split("/")[1] !== "" && this.props.location.pathname.split("/")[1] !== this.state.department){
            this.setState({
                department: this.props.location.pathname.split("/")[1]
            })
        }
    }

    /**
     * Update state on change of select tag
     * @param {event} e
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value === "null" ? null : e.target.value
        }, () => {
            this.search();
        })
    };

    search = () => {

        let search = `/${this.state.department}/?`;
        let ignore = ["regionID", "regions", "department", "types", "locations", "buyPrices", "letPrices", ""];

        for(let key in this.state){
            if(ignore.includes(key)) continue;
            if(this.state[key]) search = `${search}&${key}=${this.state[key]}`;
        }

        this.props.history.push(search);
    };

    render() {

        let from = [];
        let to = [];

        if(this.state.department === "buy"){
            from = this.state.buyPrices;
            to = this.state.buyPrices;
        } else {
            from = this.state.letPrices;
            to = this.state.letPrices;
        }

        if(this.state.priceTo){
            from = from.filter((price) => {
                return (parseInt(price) < parseInt(this.state.priceTo))
            })
        }

        if(this.state.priceFrom){
            to = to.filter((price) => {
                return (parseInt(this.state.priceFrom) < parseInt(price))
            })
        }

        let options = [];
        let North = { label: "All North", options: [] };
        let East = { label: "All East", options: [] };
        let South = { label: "All South", options: [] };
        let West = { label: "All West", options: [] };

        this.state.locations.map((location, idx) => {
            switch(location.compass){
                case "North":
                    North.options.push({value: location.regionID, label: location.region});
                    break;
                case "East":
                    East.options.push({value: location.regionID, label: location.region});
                    break;
                case "South":
                    South.options.push({value: location.regionID, label: location.region});
                    break;
                case "West":
                    West.options.push({value: location.regionID, label: location.region});
                    break;
            }
        });

        options.push(North, East, South, West);

        return (
            <div className="search" onClick={(event) => {
                if(event.target.className.includes){
                    if(event.target.className.includes("group")){
                        const label = event.target.innerHTML;
                        const locations = options.filter((group) => { return group.label === label })[0].options;

                        let selected = this.state.regionID;
                        selected = [
                            ...selected,
                            ...locations
                        ];

                        let ids = [];
                        let result = [];

                        selected.forEach((location) => {
                            if(!ids.includes(location.value)){
                                result.push(location);
                                ids.push(location.value);
                            }
                        });

                        this.setState({
                            regionID: result
                        }, () => {
                            this.search();
                        });
                    }
                }
            }}>

                <select name="region" value={this.state.region} onChange={this.updateState.bind(this)}>
                    <option value="null">All Locations</option>
                    {this.state.regions.map(region => <option value={region}>{region}</option>)}
                </select>

                <select name="department" value={this.state.department} onChange={this.updateState.bind(this)}>
                    <option value="buy">For Sale</option>
                    <option value="let">To Let</option>
                    <option value="commercial">Commercial</option>
                </select>

                <select name="priceFrom" value={this.state.priceFrom} onChange={this.updateState.bind(this)}>
                    <option value="null">Price From</option>
                    {from.map((price, idx) => {
                        return (
                            <option key={idx} value={price}>£{price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                        )
                    })}
                </select>

                <select name="priceTo" value={this.state.priceTo} onChange={this.updateState.bind(this)}>
                    <option value="null">Price To</option>
                    {to.map((price, idx) => {
                        return (
                            <option key={idx} value={price}>£{price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                        )
                    })}
                </select>

                <select name="type" value={this.state.type} onChange={this.updateState.bind(this)}>
                    <option value="null">Type</option>
                    {this.state.types.map((type, idx) => {
                        return (
                            <option key={idx} value={type}>{type}</option>
                        )
                    })}
                </select>

                <select name="bedrooms" value={this.state.bedrooms} onChange={this.updateState.bind(this)}>
                    <option value="null">Bedrooms</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5+">5+</option>
                </select>

                {/*<select name="bathrooms" value={this.state.bathrooms} onChange={this.updateState.bind(this)}>*/}
                {/*    <option value="null">Bathrooms</option>*/}
                {/*    <option value="1">1</option>*/}
                {/*    <option value="2">2</option>*/}
                {/*    <option value="3">3</option>*/}
                {/*    <option value="4">4</option>*/}
                {/*    <option value="5+">5+</option>*/}
                {/*</select>*/}

                {/*<select name="opportunities" value={this.state.opportunities} onChange={this.updateState.bind(this)}>*/}
                {/*    <option value="null">Opportunities</option>*/}
                {/*    <option value="development">Development Opportunity</option>*/}
                {/*    <option value="investment">Investment Opportunity</option>*/}
                {/*</select>*/}
            </div>
        )
    }
}

export default withRouter(Search);
