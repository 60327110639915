import React from 'react';
import { NavLink } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div>
                        <h3>Sitemap</h3>
                        <div className="horizontal">
                            <div>
                                <NavLink to="/">Home</NavLink>
                                <NavLink to="/buy">Buy</NavLink>
                                <NavLink to="/let">Let</NavLink>
                                <NavLink to="/coming-soon">Coming Soon</NavLink>
                                <NavLink to="/sell-your-property">Sell Your Property</NavLink>
                                <NavLink to="/buying-services">Buying Services</NavLink>
                            </div>
                            <div>
                                <NavLink to="/landlords">Landlords</NavLink>
                                <NavLink to="/developers">Developers</NavLink>
                                <NavLink to="/managing-agency">Managing Agency</NavLink>
                                <NavLink to="/news">News</NavLink>
                                <NavLink to="/contact">Contact</NavLink>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3>Contact</h3>
                        <div>
                            <a href="tel:+44 (0) 1624 820600">+44 (0) 1624 820600</a>
                            <a href="mailto:hello@plumproperties.im">hello@plumproperties.im</a>
                            <p>14 Tynwald Street, Douglas, Isle of Man, IM1 1BG</p>
                        </div>
                    </div>
                    <div>
                        <h3>Social</h3>
                        <div>
                            <a href="https://www.facebook.com/PlumPropertiesIOM/" target="_blank" rel="noopener">Facebook</a>
                            <a href="https://www.linkedin.com/company/plumproperties/about/" target="_blank" rel="noopener">LinkedIn</a>
                            <a href="https://www.instagram.com/plumproperties_isleofman/" target="_blank" rel="noopener">Instagram</a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="container">
                    <p>&copy; 2022 Plum Properties Ltd. All rights reserved.</p>
                    <p>Built by <a href="https://www.thomaspatrick.co" target="_blank" rel="noopener">Thomas Patrick</a></p>
                </div>
            </footer>
        );
    }
}

export default Footer;
