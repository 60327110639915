import React from 'react';

import axios from 'axios';
import {Helmet} from "react-helmet";

class ComingSoon extends React.Component {

    state = {
        properties: []
    };

    componentDidMount() {
        axios.get("https://api.plumproperties.im/db.php/comingSoon")
        .then((res) => {
            this.setState({
                properties: res.data
            })
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Coming Soon - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>Find your perfect property</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container">
                        <h2>Coming Soon</h2>
                        {this.state.properties.length > 0 ?
                            <div className="comingSoon">
                                {this.state.properties.map((property, idx) => {
                                    return (
                                        <div key={idx}>
                                            <h3>{property.title}</h3>
                                            {property.description && <p>{property.description}</p>}
                                            <div>
                                                {property.location && <p>Location: <b>{property.location}</b></p>}
                                                {property.bedrooms && <p>Bedrooms: <b>{property.bedrooms}</b></p>}
                                                {property.price && <p>Price: <b>£{property.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></p>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <p className="noProperties">We don't currently have any properties in this section, check back later.</p>
                        }
                    </div>
                </section>
            </>
        );
    }
}

export default ComingSoon;
