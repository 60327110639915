import React from 'react';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import sortObjectsArray from 'sort-objects-array';

import Search from './components/Search.js';
import Properties from './components/Properties.js';

class PropertySearch extends React.Component {

    state = {
        properties: [],
        sort: "uploaded,desc"
    };

    componentDidMount(){
        this.fetch();
    }

    componentDidUpdate(prev){
        if(prev !== this.props) this.fetch();
    }

    fetch = () => {

        let search;

        switch(this.props.location.pathname.replace(/\//g, '')){
            case "buy":
                search = "department=Sales";
                break;
            case "let":
                search = "department=Lettings";
                break;
            case "commercial":
                search = "department=Commercial";
                break;
        }

        let obj = {};
        this.props.location.search.substr(2).split("&").forEach((pair) => {
            obj[[pair.split("=")[0]]] = decodeURI(pair.split("=")[1]);
        });

        if(obj["type"]) search = `${search}&propertyTypes=${obj["type"]}`;
        // if(obj["opportunities"] === "investment"){
        //     search = `${search}&investmentOpportunity=1`;
        // }
        // if(obj["opportunities"] === "development"){
        //     search = `${search}&developmentOpportunity=1`;
        // }

        axios.get("https://api-v2.plumproperties.im/properties?"+search)
        .then((res) => {

            const regions = obj["region"] ?  obj["region"].split(",") : [];

            let properties = res.data;

            if(regions.length > 0){
                properties = properties.filter((property, idx) => {
                    return regions.includes(property.address.locality);
                });
            }

            if(obj["priceFrom"]){
                if(obj["priceFrom"] === "1000000+"){
                    properties = properties.filter((property) => {
                        return property.price.amount > 1000000
                    })
                } else {
                    properties = properties.filter((property) => {
                        return parseInt(obj["priceFrom"]) <= property.price.amount
                    })
                }
            }

            if(obj["priceTo"]){
                if(obj["priceTo"] === "1000000+"){
                    properties = properties.filter((property) => {
                        return (property.price.amount > 1000000)
                    })
                } else {
                    properties = properties.filter((property) => {
                        return parseInt(obj["priceTo"]) >= property.price.amount
                    })
                }
            }

            if(obj["bedrooms"]){
                properties = properties.filter((property) => {
                    return parseInt(property.bedrooms) >= (obj["bedrooms"] === "5+" ? 5 : obj["bedrooms"]);
                })
            }

            if(obj["bathrooms"]){
                properties = properties.filter((property) => {
                    return parseInt(property.bathrooms) >= obj["bathrooms"];
                })
            }

            if(obj["type"]) properties = properties.filter(property => property.type === obj.type)

            if(this.state.sort === "uploaded,desc") properties = properties.sort((a, b) => new Date(b.uploaded) - new Date(a.uploaded))
            if(this.state.sort === "price,asc")properties = properties.sort((a, b) => a.price.amount - b.price.amount)
            if(this.state.sort === "price,desc")properties = properties.sort((a, b) => b.price.amount - a.price.amount)

            this.setState({
                properties: properties
            })
        })
    };

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.fetch();
        })
    };

    reset = () => {
        window.location.href = this.props.location.pathname;
    };

    render() {
        console.log(this.state.properties)
        return (
            <>
                <Helmet>
                    <title>Property Search - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>Find your perfect property</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section className="grey">
                    <div className="container">
                        <Search />
                    </div>
                </section>

                <div className="filters container">
                    <div>
                        <h3>Search Results:</h3>
                        <p><b>{this.state.properties.length}</b> {this.state.properties.length === 1 ? "Property" : "Properties"} <i><span onClick={this.reset.bind(this)}>(Reset)</span></i></p>
                    </div>
                    <select name="sort" value={this.state.sort} onChange={this.updateState.bind(this)}>
                        <option value="price,desc">Price: High - Low</option>
                        <option value="price,asc">Price: Low - High</option>
                        <option value="uploaded,desc">Newest to Oldest</option>
                    </select>
                </div>

                <section>
                    <div className="container">
                        <Properties
                            properties={this.state.properties}
                        />
                    </div>
                </section>

                <img src={require('../images/street.svg')} alt="" className="street" />
            </>
        );
    }
}

export default PropertySearch;
