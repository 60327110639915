import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import axios from 'axios';

import Alerts from './components/Alerts';
import ContactForm from './components/ContactForm';
import ReactPixel from 'react-facebook-pixel';

let mapboxgl = null;

if(process.browser){
    mapboxgl = require('mapbox-gl');
    mapboxgl.accessToken = 'pk.eyJ1IjoiaW10b21nb3VnaCIsImEiOiJjOWFiN2ZjYjQ0M2I1YTM1MGI2MzU2YTBlOTc2ODJhYiJ9.F7guFhkbjvTPZJkhMElB_Q';
}

class Property extends React.Component {

    state = {
        active: 0,
        lat: 54.148607,
        lng: -4.480853,
        zoom: 14
    };

    componentDidMount() {
        ReactPixel.track("ViewContent", null);

        axios.get("https://api-v2.plumproperties.im/properties/"+this.props.match.params.id)
        .then((res) => {
            this.setState({
                ...res.data,
                images: res.data.images,
                title: `${res.data.address.name} ${res.data.address.street} ${res.data.address.locality} ${res.data.address.town}`
            }, () => {
                const map = new mapboxgl.Map({
                    container: this.mapContainer,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [this.state.longitude, this.state.latitude],
                    zoom: this.state.zoom
                });

                map.on('move', () => {
                    this.setState({
                        lng: map.getCenter().lng.toFixed(4),
                        lat: map.getCenter().lat.toFixed(4),
                        zoom: map.getZoom().toFixed(2)
                    });
                });
            });
        });
    }

    prev = () => {
        this.setState({
            active: this.state.active === 0 ? this.state.images.length - 1 : this.state.active - 1
        })
    };
    
    next = () => {
        this.setState({
            active: this.state.active === this.state.images.length - 1 ? 0 : this.state.active + 1
        })
    };

    render() {
        const property = this.state;

        return (
            <>
                {this.state.images ?
                    <>
                        <Helmet>
                            <title>{property.title} - Plum Properties Estate Agents Isle of Man</title>
                            <meta name="description" content={property.description} />
                            <meta name="og:title" content={`${property.title} - Plum Properties Estate Agents Isle of Man`} />
                            <meta name="og:description" content={property.description} />
                            <meta name="og:image" content={this.state.images[0]} />
                        </Helmet>

                        <div className="gallery">
                            {property.virtualTours?.length > 0 && this.state.active === 0 && <a href={property.virtualTours[0]} target="_blank" rel='noreferrer' className='playButton'><img src={require('../images/icons/play.svg')} alt={'Play Video Tour'} /></a>}
                            {this.state.images.map((image, idx) => {

                                const prev = this.state.active === 0 ? this.state.images.length - 1 : this.state.active - 1;
                                const next =this.state.active === this.state.images.length - 1 ? 0 : this.state.active + 1;

                                let className = "";
                                if(idx === prev) className = "prev";
                                if(idx === next) className = "next";
                                if(idx === this.state.active) className="active";

                                return (
                                    <img key={idx} src={image} className={className} alt={property.title} />
                                )
                            })}
                            <span onClick={this.prev.bind(this)}></span>
                            <span onClick={this.next.bind(this)}></span>
                        </div>

                        <section>
                            <div className="container property">
                                <div className="header">
                                    <div>
                                        <div className="headline">
                                            <h1>{property.title}</h1>
                                            {property.status !== "n/a" && <p>{property.status}</p>}
                                        </div>
                                        <div className="counts">
                                            {property.price.display && <p>£{property.price.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {property.price.rent}</p>}
                                            {property.department !== "Commercial" &&
                                                <>
                                                    {/*<div>*/}
                                                    {/*    <p>{property.receptions}</p>*/}
                                                    {/*    <img src={require('../images/icons/bedroom.svg')} alt="receptions"/>*/}
                                                    {/*</div>*/}
                                                    <div>
                                                        <p>{property.bedrooms}</p>
                                                        <img src={require('../images/icons/bedroom.svg')} alt="Bedrooms"/>
                                                    </div>
                                                    <div>
                                                        <p>{property.bathrooms}</p>
                                                        <img src={require('../images/icons/bathroom.svg')} alt="Bathrooms"/>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="flags">
                                            {/*{property.investmentOpportunity === "1" ?*/}
                                            {/*    <p>Investment Opportunity</p>*/}
                                            {/*: null}*/}
                                            {/*{property.developmentOpportunity === "1" ?*/}
                                            {/*    <p>Development Opportunity</p>*/}
                                            {/*: null}*/}
                                            {/*{JSON.parse(property.flags).map((flag, idx) => {*/}
                                            {/*    if(flag !== null){*/}
                                            {/*        return (*/}
                                            {/*            <p key={idx}>{flag}</p>*/}
                                            {/*        )*/}
                                            {/*    }*/}
                                            {/*})}*/}
                                        </div>
                                    </div>
                                    <div>
                                        {property.department === "Lettings" ?
                                            property.viewingsUnavailable ? <p className={"share"}>Viewings Unavailable</p> : <NavLink to={`/book-a-viewing/${property.title}`} className="btn">Book a
                                                Viewing</NavLink>
                                        :
                                            <>
                                                <NavLink to={`/book-a-viewing/${property.title}`} className="btn">Book a
                                                    Viewing</NavLink>
                                                <NavLink to={`/make-an-offer/${property.title}`} className="btn">Make an Offer</NavLink>
                                            </>
                                        }
                                        <div className="share">
                                            <p>Share on social media</p>
                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://plumproperties.im/property/${property.propertyid}`} target="_blank">
                                                <img src={require('../images/social/facebook.svg')} alt="Facebook" />
                                            </a>
                                            <a href={`https://www.linkedin.com/sharing/share-offsite/?url=https://plumproperties.im/property/${property.propertyid}`} target="_blank">
                                                <img src={require('../images/social/linkedin.svg')} alt="LinkedIn" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="details">

                                    {property.paragraphs?.length &&
                                        <div>
                                            {property.paragraphs.map(paragraph => {
                                                return (
                                                    <>
                                                        <h3>{paragraph.name}</h3>
                                                        <p dangerouslySetInnerHTML={{__html: paragraph.text}} />
                                                    </>
                                                )
                                            })}
                                        </div>
                                    }

                                    <div className="sidebar">
                                            <h3>Property Summary:</h3>
                                        {property.bullets?.length &&
                                            <div>
                                                <p>Property Features:</p>
                                                {property.bullets.map(bullet => <p>- {bullet}</p>)}
                                            </div>
                                        }
                                        {/*{property.tenure &&*/}
                                        {/*<div>*/}
                                        {/*    <p>Property Tenure: {property.tenure}</p>*/}
                                        {/*</div>*/}
                                        {/*}*/}
                                        {/*{parseInt(property.estimatedRentalIncome) > 0 ?*/}
                                        {/*    <div>*/}
                                        {/*        <p>Estimated Rental Income: £{property.estimatedRentalIncome}</p>*/}
                                        {/*    </div>*/}
                                        {/*    : null}*/}
                                        {property.brochures.length > 0 ?
                                            <div>
                                                <a href={property.brochures[0]} className="btn" target="_blank" rel="noopener">Brochure</a>
                                            </div>
                                            : null}
                                        {property.floorplans.length > 0 ?
                                            <div>
                                                <a href={property.floorplans[0]} className="btn" target="_blank" rel="noopener">Floorplan</a>
                                            </div>
                                            : null}
                                        {property.virtualTours?.length > 0 ?
                                            <div>
                                                <a href={property.virtualTours[0]} className="btn" target="_blank" rel="noopener">Virtual Tour</a>
                                            </div>
                                            : null}
                                        {/*{JSON.parse(property.externalLinks)[0] !== null ?*/}
                                        {/*    <div>*/}
                                        {/*        <a href={JSON.parse(property.externalLinks)[0].url ? JSON.parse(property.externalLinks)[0].url : JSON.parse(property.externalLinks)[0]} className="btn" target="_blank" rel="noopener">Video Tour</a>*/}
                                        {/*    </div>*/}
                                        {/*    : null}*/}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Alerts />

                        <ContactForm
                            primary = {false}
                            heading = "Get in touch"
                            subheading = {false}
                        />

                    </>
                :
                    false
                }

                <div ref={el => this.mapContainer = el} />
            </>
        )
    }
}

export default Property;
