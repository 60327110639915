import React from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Select from 'react-select';
import ReactPixel from 'react-facebook-pixel';

class Alerts extends React.Component {

    state = {
        name: "",
        email: "",
        phone: "",
        propertyToSell: null,
        success: false,
        error: false,
        department: "buy",
        types: [],
        locations: [],
        regionID: [],
        buyPrices: [
            "50000",
            "100000",
            "150000",
            "200000",
            "250000",
            "300000",
            "350000",
            "400000",
            "450000",
            "500000",
            "550000",
            "600000",
            "650000",
            "700000",
            "750000",
            "800000",
            "850000",
            "900000",
            "950000",
        ],
        letPrices: [
            "250",
            "500",
            "750",
            "1000",
            "1500",
            "2000",
            "3000",
            "4000",
            "5000"
        ],
        recaptcha: false,
        bedrooms: "",
        priceTo: ""
    };

    componentDidMount() {

        // Get Property Types
        axios.get("https://api.plumproperties.im/db.php/properties")
            .then((res) => {

                let types = [];

                res.data.forEach((property) => {
                    JSON.parse(property.propertyTypes).forEach((type) => {
                        if(!types.includes(type)) types.push(type);
                    })
                });

                this.setState({
                    types: types
                })
            });

        // Get Regions
        axios.get("https://api.plumproperties.im/db.php/regions")
        .then((res) => {
            this.setState({
                locations: res.data
            })
        })
    }

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    updateRegions = selectedOption => {
        this.setState({
            regionID: selectedOption
        })
    };

    verify = (token) => {
        axios
            .post('/verify', {
                response: token
            })
            .then((res) => {
                this.setState({
                    recaptcha: res.data
                })
            })
            .catch((error) => {
                console.log(error.message)
            })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let locations =  [];
        if(this.state.regionID) this.state.regionID.forEach((location, i) => { locations.push(location.value)});

        axios.post("https://api.plumproperties.im/db.php/userAlerts", {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            availability: this.state.department === "buy" ? "For Sale" : "To Let",
            location: JSON.stringify(locations),
            priceFrom: this.state.priceFrom,
            priceTo: this.state.priceTo,
            bedrooms: this.state.bedrooms,
            propertyType: this.state.type,
            propertyToSell: this.state.propertyToSell
        }).then((res) => {
            this.setState({
                success: true
            });

            axios.post("https://api.plumproperties.im/mail.php", {
                from: "noreply@plumproperties.im",
                to: "hello@plumproperties.im",
                template: "plum-properties---new-alert-alert",
                vars: {
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    availability: this.state.department === "buy" ? "For Sale" : "To Let",
                    priceFrom: this.state.priceFrom,
                    priceTo: this.state.priceTo,
                    bedrooms: this.state.bedrooms,
                    propertyType: this.state.type,
                    propertyToSell: this.state.propertyToSell
                }
            }).then((res) => {
                this.setState({
                    success: true
                });
                ReactPixel.track("CompleteRegistration", null);
            }).catch((error) => {
                console.log(error);
                this.setState({
                    error: true
                })
            });

            ReactPixel.track("SubmitApplication", null);
        }).catch((error) => {
            console.log(error);
            this.setState({
                error: true
            })
        })
    };

    render() {

        let disabled = false;
        if(!this.state.recaptcha) disabled = true;

        let from = [];
        let to = [];

        if(this.state.department === "buy"){
            from = this.state.buyPrices;
            to = this.state.buyPrices;
        } else {
            from = this.state.letPrices;
            to = this.state.letPrices;
        }

        if(this.state.priceTo){
            from = from.filter((price) => {
                return (parseInt(price) < parseInt(this.state.priceTo))
            })
        }

        if(this.state.priceFrom){
            to = to.filter((price) => {
                return (parseInt(this.state.priceFrom) < parseInt(price))
            })
        }

        let options = [];
        let North = { label: "North", options: [] };
        let East = { label: "East", options: [] };
        let South = { label: "South", options: [] };
        let West = { label: "West", options: [] };

        this.state.locations.map((location, idx) => {
            switch(location.compass){
                case "North":
                    North.options.push({value: location.regionID, label: location.region});
                    break;
                case "East":
                    East.options.push({value: location.regionID, label: location.region});
                    break;
                case "South":
                    South.options.push({value: location.regionID, label: location.region});
                    break;
                case "West":
                    West.options.push({value: location.regionID, label: location.region});
                    break;
            }
        });

        options.push(North, East, South, West);

        return (
            <section className="primary">
                <div className="container alerts">
                    <h2>Set up for property alerts</h2>
                    <p>Find your dream property - sign up below</p>
                    {this.state.error ?
                        <p className="sent">There was an error creating your alert, please try again.</p>
                    :
                        this.state.success ?
                            <p className="sent">Your alert has been created! We'll email you when a new property matches your criteria.</p>
                        :
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className="fields" onClick={(event) => {
                                    if(event.target.className.includes){
                                        if(event.target.className.includes("group")){
                                            const label = event.target.innerHTML;
                                            const locations = options.filter((group) => { return group.label === label })[0].options;

                                            let selected = this.state.regionID;
                                            selected = [
                                                ...selected,
                                                ...locations
                                            ];

                                            let ids = [];
                                            let result = [];

                                            selected.forEach((location) => {
                                                if(!ids.includes(location.value)){
                                                    result.push(location);
                                                    ids.push(location.value);
                                                }
                                            });

                                            this.setState({
                                                regionID: result
                                            });
                                        }
                                    }
                                }}>
                                    <input type="text" name="name" placeholder="Full Name" value={this.state.name} onChange={this.updateState.bind(this)} required={true} />
                                    <input type="email" name="email" placeholder="Email Address" value={this.state.email} onChange={this.updateState.bind(this)} required={true} />
                                    <input type="tel" name="phone" placeholder="Phone Number" value={this.state.phone} onChange={this.updateState.bind(this)} />
                                    <Select
                                        placeholder={"Locations"}
                                        className={"select"}
                                        value={this.state.regionID}
                                        isMulti={true}
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={this.updateRegions}
                                        options={options}
                                    />
                                    <select name="department" value={this.state.department} onChange={this.updateState.bind(this)}>
                                        <option value="buy">For Sale</option>
                                        <option value="let">To Let</option>
                                    </select>
                                    <select name="priceFrom" value={this.state.priceFrom} onChange={this.updateState.bind(this)}>
                                        <option value="null">Price From</option>
                                        {from.map((price, idx) => {
                                            return (
                                                <option key={idx} value={price}>£{price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                                            )
                                        })}
                                    </select>
                                    <select name="priceTo" value={this.state.priceTo} onChange={this.updateState.bind(this)} required={true}>
                                        <option value="" disabled={true}>Price To</option>
                                        {to.map((price, idx) => {
                                            return (
                                                <option key={idx} value={price}>£{price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                                            )
                                        })}
                                    </select>
                                    <select name="type" value={this.state.type} onChange={this.updateState.bind(this)}>
                                        <option value="null">Type</option>
                                        {this.state.types.map((type, idx) => {
                                            return (
                                                <option key={idx} value={type}>{type}</option>
                                            )
                                        })}
                                    </select>
                                    <select name="bedrooms" value={this.state.bedrooms} onChange={this.updateState.bind(this)} required={true}>
                                        <option value="" disabled={true}>Bedrooms</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5+">5+</option>
                                    </select>
                                    <select name="propertyToSell" value={this.state.propertyToSell} onChange={this.updateState.bind(this)}>
                                        <option value="null">Property to sell?</option>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>

                                <ReCAPTCHA
                                    sitekey="6Lf7dc4UAAAAAK23X3vcZhaLK1phSt9OmRAGdRaM"
                                    onChange={this.verify.bind(this)}
                                />
                                <button disabled={disabled} className="btn btnWhite">Create Alert</button>
                            </form>
                    }

                </div>
            </section>
        );
    }
}

export default Alerts;
