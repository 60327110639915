import React from 'react';
import { Helmet } from 'react-helmet';

import ContactForm from './components/ContactForm';

let mapboxgl = {};

if(process.browser){
    mapboxgl = require('mapbox-gl');
    mapboxgl.accessToken = 'pk.eyJ1IjoiaW10b21nb3VnaCIsImEiOiJjOWFiN2ZjYjQ0M2I1YTM1MGI2MzU2YTBlOTc2ODJhYiJ9.F7guFhkbjvTPZJkhMElB_Q';
}

class Contact extends React.Component {

    state = {
        lat: 54.148607,
        lng: -4.480853,
        zoom: 15
    };

    componentDidMount(){
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });

        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Contact - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <ContactForm
                    primary = {false}
                    heading = "Get in touch"
                    subheading = {false}
                />

                <div ref={el => this.mapContainer = el} />
            </>
        );
    }
}

export default Contact;
