import React from 'react';

import Property from './Property.js';

class Properties extends React.Component {
    render() {
        return (
            this.props.properties.length > 0 ?
                <div className="properties">
                    {this.props.properties.map((property, idx) => {
                        return (
                            <Property
                                key={idx}
                                property={property}
                            />
                        ) 
                    })}
                </div>
            : <p className="noProperties">No properties found, please try adjusting your search.</p>
        )
    }
}

export default Properties;
