import React from 'react';

import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

class BookAViewing extends React.Component {

    state = {
        property: "",
        name: "",
        email: "",
        phone: "",
        comments: ""
    };

    componentDidMount() {
        this.setState({
            property: this.props.match.params.property
        })
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    verify = (token) => {
        axios
            .post('/verify', {
                response: token
            })
            .then((res) => {
                this.setState({
                    recaptcha: res.data
                })
            })
            .catch((error) => {
                console.log(error.message)
            })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
        const nameValid = regex.test(this.state.name);
        const emailValid = regex.test(this.state.email);
        const phoneValid = regex.test(this.state.phone);

        if(!nameValid || !emailValid || !phoneValid){
            alert("Please ensure you have filled out your name, email address and phone number.");
        } else {
            axios.post("https://api.plumproperties.im/mail.php", {
                from: "noreply@plumproperties.im",
                to: "hello@plumproperties.im",
                template: "plum-properties---book-a-viewing",
                vars: {
                    property: this.state.property,
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    comments: this.state.comments
                }
            }).then((res) => {
                this.setState({
                    success: true
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    error: true
                })
            });
        }
    };

    render() {

        let disabled = false;
        if(!this.state.recaptcha) disabled = true;

        return (
            <>
                <Helmet>
                    <title>Book A Viewing - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <section className="primary">
                    <div className="container alerts">
                        <h2>Book A Viewing</h2>
                        {this.state.error ?
                            <p className="sent">There was an error sending your message, please try again.</p>
                            :
                            this.state.success ?
                                <p className="sent">Your message was sent!</p>
                                :
                                <form onSubmit={this.handleSubmit.bind(this)}>
                                    <div className="fields">
                                        <input type="text" name="property" disabled={true} placeholder="Property" value={this.state.property} />
                                        <input type="text" name="name" placeholder="Full Name" value={this.state.name} onChange={this.updateState.bind(this)} />
                                        <input type="email" name="email" placeholder="Email Address" value={this.state.email} onChange={this.updateState.bind(this)} />
                                        <input type="tel" name="phone" placeholder="Phone Number" value={this.state.phone} onChange={this.updateState.bind(this)} />
                                    </div>
                                    <textarea name="comments" value={this.state.comments} onChange={this.updateState.bind(this)} placeholder="Comments" rows={10} />

                                    <ReCAPTCHA
                                        sitekey="6Lf7dc4UAAAAAK23X3vcZhaLK1phSt9OmRAGdRaM"
                                        onChange={this.verify.bind(this)}
                                    />
                                    <button disabled={disabled} className="btn btnWhite">Submit</button>
                                </form>
                        }

                    </div>
                </section>
            </>
        );
    }
}

export default BookAViewing;
