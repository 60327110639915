import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

import SavingsCalculator from './components/SavingsCalculator';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';

class SellYourProperty extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Sell Your Property - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>Discover the faster, smarter, better way to sell your home</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container center">
                        <h2>Sell your property</h2>
                        <p>If you are considering selling your property, then look no further. We offer the latest marketing techniques and the state of the art technological features to sell your property in an affordable and transparent way.</p>
                    </div>
                </section>

                <section className="grey">
                    <div className="container menu">
                        <h3>1. Our Fees</h3>
                        <div className="feeOptions">
                            <div>
                                <div>
                                    <p>1% + VAT</p>
                                </div>
                            </div>
                        </div>

                        <h3>2. The services we provide</h3>
                        <div className="services">
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Free Property Appraisal</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Professional Photographs</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>For Sale Board</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Marketed on Plum Website</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Marketed on Plum Social Media Sites</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Marketed on Independent Platforms</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>24/7 Access to Plum Website &amp; Portal</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Floor Plan</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Accompanied Viewings</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Negotiated Sales</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/tick.svg')} alt="Tick" />
                                <p>Property Video</p>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactForm
                    primary = {true}
                    appraisal = {true}
                    heading = "Book A Free Property Appraisal"
                    subheading = "You are just a few clicks away from booking your free property appraisal."
                />

                <Testimonials />
            </>
        );
    }
}

export default withRouter(SellYourProperty);
