import React from 'react';
import { Helmet } from 'react-helmet';

import ReactPixel from 'react-facebook-pixel';

class ReservationAgreementSuccess extends React.Component {

    componentDidMount() {
        ReactPixel.init('2289926061323683');
        ReactPixel.track("Lead", null);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Reservation Agreement - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container center">
                        <h2>Thank You!</h2>
                        <p>We've received your enquiry and will get in touch soon.</p>
                        <br /><br />
                        <a href="/" className="btn btnPrimary">Back to Home</a>
                    </div>
                </section>
            </>
        );
    }
}

export default ReservationAgreementSuccess;
