import React from 'react';
import { Helmet } from 'react-helmet';

import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';

import ReactPixel from 'react-facebook-pixel';

class Landlords extends React.Component {

    state = {
        services: [
            {
                name: "Fee",
                full: "10% of monthly rent collected",
                letting: "1/2 of the first months rent"
            }, {
                name: "Free Appraisal",
                full: true,
                letting: true
            }, {
                name: "Professional Photographs",
                full: true,
                letting: true
            }, {
                name: "To Let Board",
                full: true,
                letting: true
            }, {
                name: "Marketed on Plum Website",
                full: true,
                letting: true
            }, {
                name: "Marketed on Plum Social Media Sites",
                full: true,
                letting: true
            }, {
                name: "Marketed in Independent Property Platforms",
                full: true,
                letting: true
            }, {
                name: "Accompanied Viewings",
                full: true,
                letting: true
            }, {
                name: "Professional Lettings Contract",
                full: true,
                letting: true
            }, {
                name: "Full Property Management Service",
                full: true,
                letting: false
            }, {
                name: "Comprehensive Reference Checks",
                full: true,
                letting: false
            }, {
                name: "Inventory Reviews",
                full: true,
                letting: false
            }, {
                name: "Property Health & Safety Checks",
                full: true,
                letting: false
            }, {
                name: "Address All Property Maintenance Issues",
                full: true,
                letting: false
            }, {
                name: "Mid Terms and Closing Inspecitions",
                full: true,
                letting: false
            }, {
                name: "Sensitive Processing of End of Term Discrepancies",
                full: true,
                letting: false
            }
        ]
    };

    componentDidMount() {
        ReactPixel.track("Schedule", null);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Landlords - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container">
                        <h2>Allow Plum to take the strain out of property management</h2>
                        <div className="landlords">
                            <div>
                                <div>
                                    <img src={require('../images/icons/cost-effective.png')} alt="Cost Effective" />
                                    <h3>Cost Effective</h3>
                                </div>
                                <p>It is important to us that we are able to offer our clients value for money for the service that we provide.</p>
                                <p>Whether that is fully managing your property for you, or simply finding you a suitable tenant.</p>
                                <p>We believe that our prices won’t be bettered and in the unlikely event that does happen, we are willing to match any like for like written quote you have received elsewhere.</p>
                            </div>
                            <div>
                                <div>
                                    <img src={require('../images/icons/lettings-specialist.png')} alt="Lettings Specialist" />
                                    <h3>Lettings Specialist</h3>
                                </div>
                                <p>We have been providing a quality property lettings and property management service to Isle of Man residential properties since 2015.</p>
                                <p>Our staff consist of highly experienced residential property managers and qualified property lettings and property management professionals.</p>
                                <p>We are therefore able to focus our attentions on providing you with a dedicated professional and efficient service.</p>
                            </div>
                            <div>
                                <div>
                                    <img src={require('../images/icons/dedicated-property-manager.png')} alt="Dedicated Property Manager" />
                                    <h3>Dedicated Property Manager</h3>
                                </div>
                                <p>We believe that the best way in which we can create satisfied customers is though the service that we provide. In order to achieve this we offer a dedicated property manager to each landlord, who is also accessible to you outside of office hours.</p>
                                <p>They will use their knowledge, experience and professionalism to deal with any issues or challenges that are presented with your property so that you don’t need to worry.</p>
                                <p>This will include inventory checks at the start and end of any tenancy period, as well as mid-term inspections on properties let for longer periods.</p>
                            </div>
                            <div>
                                <div>
                                    <img src={require('../images/icons/high-occupancy.png')} alt="High Rates of Occupancy" />
                                    <h3>High Rates of Occupancy</h3>
                                </div>
                                <p>Due to our extensive online following and relationships with professional relocation companies we are able to match professional tenants to quality properties quickly and efficiently.</p>
                                <p>We carry out extensive background checks on potential tenants prior to allowing them to enter into an agreement to lease a property, ensuring that you can rest easy knowing that you have a suitable tenant residing in your property.</p>
                            </div>
                            <div>
                                <div>
                                    <img src={require('../images/icons/online-focused.png')} alt="Online Focused" />
                                    <h3>Online Focused</h3>
                                </div>
                                <p>We believe that the most efficient and effective way in which to operate a lettings business is as an online focused agency.</p>
                                <p>This means that we do not operate an expensive shop frontage, and we ensure the savings that are made are passed onto you directly by providing our services at a competitive price.</p>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Service</th>
                                    <th>Full Property Management</th>
                                    <th>Letting Only</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.services.map((service, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{service.name}</td>
                                            <td>
                                                {service.full === true ?
                                                    <img src={require('../images/icons/tick-white.svg')} alt="Tick" />
                                                : service.full === false ?
                                                    <img src={require('../images/icons/cross-white.svg')} alt="Tick" />
                                                : <p>{service.full}</p> }
                                            </td>
                                            <td>
                                                {service.letting === true ?
                                                    <img src={require('../images/icons/tick-white.svg')} alt="Tick" />
                                                : service.letting === false ?
                                                    <img src={require('../images/icons/cross-white.svg')} alt="Tick" />
                                                : <p>{service.letting}</p> }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </section>
                
                <ContactForm
                    primary = {true}
                    heading = "Want To Find Out More?"
                    subheading = "Allow us to take the stress out of property management for you."
                />

                <Testimonials />
            </>
        );
    }
}

export default Landlords;
