import React from 'react';

import axios from 'axios';

class Testimonials extends React.Component {

    state = {
        testimonials: []
    };

    componentDidMount() {
        axios.get("https://api.plumproperties.im/db.php/testimonials").then((res) => {
            this.setState({
                testimonials: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }


    render() {
        return (
            <>
                <section>
                    <div className="container">
                        <h2>Testimonials</h2>
                        <div className="testimonials">
                            {this.state.testimonials.map((testimonial, idx) => {
                                return (
                                    <div key={idx}>
                                        <img src={require('../../images/icons/quote.svg')} alt="Quote" />
                                        <p>{testimonial.body}</p>
                                        <div>
                                            <img src={require('../../images/icons/board.svg')} alt="Plum Properties" />
                                            <div>
                                                <p>{testimonial.authorName}</p>
                                                <p>{testimonial.authorSubHeading}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
                <img src={require('../../images/street.svg')} alt="" className="street" />
            </>
        );
    }
}

export default Testimonials;
