import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import moment from 'moment';

class Article extends React.Component {

    state = {
        id: null,
        image: null,
        title: null,
        content: null,
        date: null
    };

    componentDidMount(){
        axios.get("https://api.plumproperties.im/db.php/news/"+this.props.match.params.id)
        .then((res) => {
            this.setState(res.data[0]);
        })
    }

    render() {
        if(this.state.id){
            return (
                <>
                    <Helmet>
                        <title>{this.state.title} - Plum Properties Estate Agents Isle of Man</title>
                        <meta name="description" content={this.state.content} />
                        <meta name="og:title" content={`${this.state.title} - Plum Properties Estate Agents Isle of Man`} />
                        <meta name="og:description" content={this.state.content} />
                        <meta name="og:image" content={`https://manage.plumproperties.im${this.state.image}`} />
                    </Helmet>

                    <header>
                        <div>
                            <h1>{this.state.title}</h1>
                            <p><i>{moment(this.state.date).format('Do MMMM YYYY')}</i></p>
                        </div>
                        <img src={require('../images/header.jpg')} />
                    </header>

                    <section>
                        <div className="container" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                    </section>

                </>
            );
        } else {
            return false;
        }
    }
}

export default Article;
