import App from './app/App';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';

hydrate(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
