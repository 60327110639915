import React from 'react';
import { Helmet } from 'react-helmet';

import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';

class ManagingAgency extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Managing Agency - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container center">
                        <h2>Allow Plum to take the strain out of property management</h2>
                        <p>If you require your apartment block or property to be managed on a highly professional basis, then we offer an excellent service, allowing you to choose from one of three transparent and flexible charging structures.</p>
                    </div>
                </section>

                <section className="grey">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Pay As You Go</th>
                                    <th>Contract</th>
                                    <th>All Inclusive</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>No Minimum Fee</td>
                                    <td>No Minimum Fee</td>
                                    <td>No Minimum Fee</td>
                                </tr>
                                <tr>
                                    <td>We simply charge you on a time spent basis at a competitive hourly rate.</td>
                                    <td>A generous discount is applied to buildings with 20 or more units.</td>
                                    <td>A generous discount is applied to buildings with 20 or more units.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Proactive preventative property management.</td>
                                    <td>All of the services provided as a ‘Contract’’ charging structure, plus;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Ongoing Management of essential services.</td>
                                    <td>Project Management of major refurbishment and maintenance projects.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Management and Supervision of standard maintenance work.</td>
                                    <td>Out of hours emergency services.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Preparation of Accounts.</td>
                                    <td>Full Property Management.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Provision of Company Secretarial services.</td>
                                    <td>Discreet Management of Debtors.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Arranging relevant insurance.</td>
                                    <td>Optional manned maintenance service desk.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Additional services charged at a competitive hourly rate.</td>
                                    <td>No hidden or additional fees.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <ContactForm
                    primary = {true}
                    heading = "Want To Find Out More?"
                    subheading = "Allow us to take the stress out of property management for you."
                />

                <Testimonials />
            </>
        );
    }
}

export default ManagingAgency;
