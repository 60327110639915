import React from 'react';
import { NavLink } from 'react-router-dom';

class Property extends React.Component {
    render() {

        const property = this.props.property;
        property.title = `${property.address.name} ${property.address.street} ${property.address.locality} ${property.address.town}`;

        return (
            <NavLink to={`/property/${property.propertyid}`}>
                <div>
                    <p className="availability">{property.status}</p>
                    {/*{property.investmentOpportunity === "1" ? <p className="opportunity">Investment Opportunity</p> : property.developmentOpportunity === "1" ? <p className="opportunity">Development Opportunity</p> : null }*/}
                    {property.thumbnail ?
                        <img src={property.thumbnail} alt={property.title} />
                    : <img src={require('../../images/placeholder.jpg')} alt="Placeholder" /> }
                </div>
                <div>
                    <div>
                        <p>{property.title}</p>
                        {property.price.display && <p>£{property.price.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {property.price.rent}</p>}
                    </div>
                    <div>
                        {property.department !== "Commercial" &&
                            <>
                                <div>
                                    <img src={require('../../images/icons/bedroom.svg')} alt="Bedrooms"/>
                                    <p>{property.bedrooms}</p>
                                </div>
                                <div>
                                    <img src={require('../../images/icons/bathroom.svg')} alt="Bathroom" />
                                    <p>{property.bathrooms}</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </NavLink>
        )
    }
}

export default Property;