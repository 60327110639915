import React from 'react';
import { Helmet } from 'react-helmet';

import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';

class BuyingServices extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Buying Services - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container">
                        <div className="bullets">
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Are you looking to purchase a property in the Isle of Man, but don’t know where to start?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Would you prefer the support of property professionals to represent you in every step of the way to buying a property?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Are you resident outside of the Isle of Man, and therefore need the benefit of local knowledge of the Isle of Man property market?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Would you benefit from using the services of a property professional who will leave no stone unturned in finding your dream home?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Does using a property professional to negotiate the purchase price of your chosen property sound appealing?</p>
                            </div>
                        </div>
                        <p>If this sounds like you could benefit from these services, get in touch for a non-obligatory conversation, as we might be exactly what you are looking for. We will carry out these services on your behalf to give you complete peace of mind that you are purchasing the right property for the right price. All of this for a low and competitive charge out rate.</p>
                        <p>In situations whereby you purchase a property that we are selling for the vendor, we are unable to negotiate on your behalf, however in such circumstances, should the purchase complete we will wave all of our fees for the services that we have provided.</p>
                    </div>
                </section>
                
                <ContactForm
                    primary = {true}
                    heading = "Book A Free Consultation"
                    subheading = "You might be just a few clicks away from finding your dream home."
                />

                <Testimonials />
            </>
        );
    }
}

export default BuyingServices;
