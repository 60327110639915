import React from 'react';

import Alerts from './components/Alerts';

import { Helmet } from 'react-helmet';

class SignUp extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Sign Up for Property Alerts - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <Alerts />
            </>
        );
    }
}

export default SignUp;
