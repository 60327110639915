import React from 'react';
import { Helmet } from 'react-helmet';

import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';

class Developers extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Developers - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container">
                        <div className="bullets">
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Are you seeking development finance, either debt, equity or a combination?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Are you looking for properties to modernise?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Are you looking for properties to renovate?</p>
                            </div>
                            <div>
                                <img src={require('../images/icons/board.svg')} alt="Plum Properties" />
                                <p>Are you looking for development land or development opportunities?</p>
                            </div>
                        </div>
                        <p>Whether your property development business is seeking to modernise a single property or carry out a major development project, there are many ways in which Plum can assist.</p>
                        <p>We are able to introduce you to our contacts, who specialise in providing funding for property related projects, whether that be debt or equity finance or a combination of the two.</p>
                        <p>You can also use our property search facility here to help find your perfect development project.</p>
                    </div>
                </section>
                
                <ContactForm
                    primary = {true}
                    heading = "Want To Find Out More?"
                    subheading = "Get in touch to discuss the projects and development opportunities you are seeking."
                />

                <Testimonials />
            </>
        );
    }
}

export default Developers;
