import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import Search from './components/Search.js';
import Properties from './components/Properties';
import Testimonials from './components/Testimonials';

class Home extends React.Component {

    state = {
        featured: [],
        sales: [{
            name: 'Emma Clarke',
            title: 'Sales Negotiator',
            email: 'emma@plumproperties.im',
            phone: '01624 820600',
            image: 'emma.jpg'
        }, {
            name: 'Jamie Sutton',
            title: 'Sales Director',
            email: 'jamie@plumproperties.im',
            phone: '07624 499615',
            image: 'jamie.jpg'
        }],
        rentals: [{
            name: 'Adam McClean',
            title: 'Lettings Negotiator',
            email: 'adam@plumproperties.im',
            phone: '01624 820600',
            image: 'adam.jpg'
        }, {
            name: 'Ceri Henderson',
            title: 'Lettings Negotiator',
            email: 'ceri@plumproperties.im',
            phone: '01624 820600',
            image: 'ceri.jpg'
        }, {
            name: 'Elissa Nott',
            title: 'Lettings Negotiator',
            email: 'elissa@plumproperties.im',
            phone: '01624 820600',
            image: 'elissa.jpg'
        }],
        maintenance: [{
            name: 'Paul Jenkins',
            title: 'Maintenance Manager',
            email: 'paul@plumproperties.im',
            phone: '07624 497010',
            image: 'paul.jpg'
        }, {
            name: 'Rodger Gimbert',
            title: 'Property Manager',
            email: 'rodger@plumproperties.im',
            phone: '07624 287211',
            image: 'rodger.jpg'
        }],
        directors: [{
            name: 'Jamie Sutton',
            title: 'Director',
            email: 'jamie@plumproperties.im',
            phone: '07624 499615',
            image: 'jamie.jpg'
        }, {
            name: 'Jonathan Leece',
            title: 'Director',
            email: 'hello@plumproperties.im',
            phone: '01624 820600',
            image: 'jonathan.jpg'
        }, {
            name: 'Liam Perry',
            title: 'Director',
            email: 'hello@plumproperties.im',
            phone: '01624 820600',
            image: 'liam.jpg'
        }]
    };

    componentDidMount() {
        axios.get("https://api-v2.plumproperties.im/properties?featured=true")
        .then((res) => {
            this.setState({
                featured: res.data
            });
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Plum Properties Estate Agents - Find your perfect home on the Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header className="large">
                    <div>
                        <h1>Find your perfect home</h1>
                        <Search />
                        <div>
                            <Link to="/sell-your-property#anchor" className="btn" >Book an appraisal</Link>
                            <NavLink to="/sign-up" className="btn" >Create property alert</NavLink>
                        </div>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container">
                        <h2>Featured Properties</h2>
                        <Properties
                            properties={this.state.featured}
                        />
                        <NavLink to="/buy" className="btn center">See All Properties</NavLink>
                    </div>
                </section>

                <div className="split">
                    <section className="grey">
                        <div>
                            <h2>About</h2>
                            <p>We are a fully licensed online focused Isle of Man Estate Agency. Whether marketing and selling your property, helping you find your dream home or letting and managing your property, our focus is on delivering the best service and experience that we can in a cost effective way ensuring that you are receiving value for your money..</p>
                            <p>We do this by utilising state of the art marketing techniques including targeted online advertising through multiple mediums and platforms to ensure your property reaches the right audience. Our functionality allow you to book property valuations and property viewings, sign-up for tailored property alerts and track the progress of your property transaction 24 hours a day.</p>
                            <p>Being online focused affords us to pass on cost efficiencies to our customers, meaning we can provide all of the services of a local estate agency in an affordable, flexible and transparent way.</p>
                        </div>
                    </section>
                    <img src={require('../images/about.jpg')} alt="Plum Properties" />
                </div>

                <section>
                    <div className="container">
                        <h2 className="left">History of Plum</h2>
                        <p>We started out life as a residential property lettings and management business in 2015 under the name of Total Media Rentals Ltd for a portfolio of private clients. Following successfully obtaining our Isle of Man Estate Agents licence we are trading as Plum Properties and have expanded these services to include selling residential property and representing buyers to help them find their dream Isle of Man home. If you feel you could benefit from our services, please do get in contact.</p>
                    </div>
                </section>

                <section className="grey">
                    <div className="container">
                        <h2>Meet the Plum team</h2>
                        <p className="center">We love to hear from you and discuss how we can assist with your property
                            requirements.</p>
                        <div className="team">
                            <h3>Sales Team</h3>
                            <div>
                                {this.state.sales.map((person, idx) => {
                                    return (
                                        <div key={idx}>
                                            <img src={require(`../images/team/${person.image}`)} alt={person.name}/>
                                            <h3>{person.name}</h3>
                                            <p>{person.title}</p>
                                            <a href={`mailto:${person.email}`}>{person.email}</a>
                                            <a href={`tel:${person.phone}`}>{person.phone}</a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="team">
                            <h3>Rentals Team</h3>
                            <div>
                                {this.state.rentals.map((person, idx) => {
                                    return (
                                        <div key={idx}>
                                            <img src={require(`../images/team/${person.image}`)} alt={person.name}/>
                                            <h3>{person.name}</h3>
                                            <p>{person.title}</p>
                                            <a href={`mailto:${person.email}`}>{person.email}</a>
                                            <a href={`tel:${person.phone}`}>{person.phone}</a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="team">
                            <h3>Management & Maintenance</h3>
                            <div>
                                {this.state.maintenance.map((person, idx) => {
                                    return (
                                        <div key={idx}>
                                            <img src={require(`../images/team/${person.image}`)} alt={person.name}/>
                                            <h3>{person.name}</h3>
                                            <p>{person.title}</p>
                                            <a href={`mailto:${person.email}`}>{person.email}</a>
                                            <a href={`tel:${person.phone}`}>{person.phone}</a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="team">
                            <h3>Directors</h3>
                            <div>
                                {this.state.directors.map((person, idx) => {
                                    return (
                                        <div key={idx}>
                                            <img src={require(`../images/team/${person.image}`)} alt={person.name}/>
                                            <h3>{person.name}</h3>
                                            <p>{person.title}</p>
                                            <a href={`mailto:${person.email}`}>{person.email}</a>
                                            <a href={`tel:${person.phone}`}>{person.phone}</a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>

                <Testimonials />
            </>
        );
    }
}

export default Home;
