import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

class Nav extends React.Component {

    state = {
        open: false
    };

    componentDidMount(){
        window.addEventListener("resize", this.closeNav);
    }

    closeNav = () => {
        if(this.state.open === true){
            if(window.innerWidth > 999){
                this.setState({
                    open: false
                })
            }
        }
    };

    /**
     * Open or close the mobile nav
     */
    toggleNav = () => {
        this.setState({
            open: !this.state.open
        })
    };

    render() {
        return (
            <div className="nav">
                <div className="container">
                    <NavLink to="/">
                        <img src={require('../../images/logo.svg')} alt="Plum Properties" />
                    </NavLink>
                    <div>
                        <Link to="/sell-your-property#anchor" className="btn">Book an appraisal</Link>
                        <NavLink to="/sign-up" className="btn">Create property alert</NavLink>
                        <NavLink to="/buy" className="btn">View all properties</NavLink>
                    </div>
                    <div>
                        <a href="tel:+44 (0) 1624 820600">+44 (0) 1624 820600</a>
                        <a href="mailto:hello@plumproperties.im">hello@plumproperties.im</a>
                        <div>
                            <a href="https://www.facebook.com/PlumPropertiesIOM/" target="_blank" rel="noopener">
                                <img src={require('../../images/social/facebook.svg')} alt="Facebook" />
                            </a>
                            <a href="https://www.linkedin.com/company/plumproperties/about/" target="_blank" rel="noopener">
                                <img src={require('../../images/social/linkedin.svg')} alt="LinkedIn" />
                            </a>
                            <a href="https://www.instagram.com/plumproperties_isleofman/" target="_blank" rel="noopener">
                                <img src={require('../../images/social/instagram.svg')} alt="Instagram" />
                            </a>
                        </div>
                    </div>
                    <div id="hamburger" onClick={this.toggleNav} className={this.state.open ? "close" : null}><span></span></div>
                </div>
                <nav className={this.state.open ? "visible" : null} onClick={this.toggleNav.bind(this)}>
                    <div className="container">
                        <NavLink to="/" exact={true}>Home</NavLink>
                        <NavLink to="/buy">Buy</NavLink>
                        {/*<a href={"https://secure.gazeal.co.uk/plum-properties/"} target={"_blank"} rel={"noreferrer"}>Reservation Agreement</a>*/}
                        <NavLink to="/let">Let</NavLink>
                        <NavLink to="/commercial">Commercial</NavLink>
                        <NavLink to="/coming-soon">Coming Soon</NavLink>
                        <NavLink to="/sell-your-property">Sell Your Property</NavLink>
                        <NavLink to="/buying-services">Buying Services</NavLink>
                        <NavLink to="/landlords">Landlords</NavLink>
                        <NavLink to="/developers">Developers</NavLink>
                        <NavLink to="/managing-agency">Managing Agency</NavLink>
                        <NavLink to="/news">News</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Nav;
