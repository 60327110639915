import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import moment from 'moment';

class News extends React.Component {

    state = {
        news: []
    };

    componentDidMount(){
        axios.get("https://api.plumproperties.im/db.php/news/search?id=*&SORT=date,DESC")
        .then((res) => {
            console.log(res.data);
            this.setState({
                news: res.data
            })
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Latest News - Plum Properties Estate Agents Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header>
                    <div>
                        <h1>The Value Estate Agent</h1>
                    </div>
                    <img src={require('../images/header.jpg')} />
                </header>

                <section>
                    <div className="container news">
                        {this.state.news.map((item, idx) => {
                            return (
                                <NavLink key={idx} to={`/news/${item.id}`}>
                                    <div>
                                        <h3>{item.title}</h3>
                                        <p><i>{moment(item.date).format('Do MMMM YYYY')}</i></p>
                                    </div>
                                    <span></span>
                                    <img src={`https://manage.plumproperties.im${item.image}`} alt={item.title} />
                                </NavLink>
                            )
                        })}
                    </div>
                </section>

            </>
        );
    }
}

export default News;
