import React from "react";
import { withRouter } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
            ReactPixel.pageView();
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);